export const tableColumn = [
	{
		label: '姓名',
		prop: 'username',
		width: '120px',
		
	},
	{
		label: '部门',
		prop: 'deptName',
	},
	{
		label: '业绩',
		prop: 'amount',
	},
	{
		label: '入职日期',
		prop: 'hiredate',
	},
]
