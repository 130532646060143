<template>
  <div>
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @reset="handleReset"
      @submit="handleSubmit"
      @changeDept="changeDept"
    />

    <div style="margin-bottom: 15px; font-size: 14px;font-weight: 800; color: #606266">当前总业绩为{{ totalCollection }}元,请继续加油!</div>

    <RankTable
      v-if="tableData"
      :tableColumn="tableColumn"
      :tableData="tableData"
      @nameDrawer="handleNameDrawer"
      :page="pagination.page"
      :size="pagination.size"
    />
    <RankPagination
      :total="total"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />
  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn } from "./index.js";
import RankTable from "@/components/table";
import RankPagination from "@/components/pagination";
import { useStore } from "vuex";
import { reset } from "@/until";
import { getUserByDept } from "@/api/method/common";

export default {
  name: "LeaderBoard",
  components: {
    FilterForm,
    RankTable,
    RankPagination,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
      date: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择顾问",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "100px",
        },

        {
          label: "操作时间",
          type: "dateRange",
          attr: "date",
          column: 8,
          labelWidth: "100px",
        },
        {
          type: "button",
          column: 4,
        },
      ],
    ]);

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const tableData = computed(() => state.rankList && state.rankList.records);
    const total = computed(() => state.rankList && state.rankList.total);
    const totalCollection = computed(() => state.rankList && state.rankList.totalCollection);

    const getList = () => {
      const { date, ...param } = filterData;

      const startTime = date && date[0];
      const endTime = date && date[1];
      dispatch("rankList", {
        ...pagination,
        startTime,
        endTime,
        ...param,
      });
    };

    onMounted(() => {
      getList();
      getDepartList();
    });

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };
    const isShowDialog = ref(false);
    const handleClose = () => (isShowDialog.value = false);
    const userId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDialog.value = true;
      userId.value = row.userId;
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    return {
      filters,
      filterData,
      tableColumn,
      tableData,
      total,
      totalCollection,
      handleReset,
      handleSubmit,
      changeDept,
      isShowDialog,
      userId,
      handleClose,
      handleNameDrawer,
      pagination,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
